import React, { useContext, useEffect } from 'react';
import { Row, Col, Pagination } from 'antd';
import Container from '../components/Container';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import { beforeDownload, hashSectionScrollIntoView } from '../utils/common';
import ApplyDialogContext from '../context/applyDialog';
import CustomPaginationItem from '../components/CustomPaginationItem';
import SubscribeBanner from '../components/SubscribeBanner';

// eslint-disable-next-line react/prop-types
function Article({ title, cover, description, href, id, titleExtra = '', pageIndex = 0 }) {
  const { setHref, setModalType, setFileId } = useContext(ApplyDialogContext);

  const download = () => {
    beforeDownload(href, true, id, setHref, setModalType, null, null, setFileId, pageIndex);
  };

  return (
    <Row className="article whitepaper-article" gutter={30} id={`section-${id}`}>
      <Col lg={{ span: 10 }} xs={{ span: 24 }}>
        <a onClick={download}>
          <img alt="cover" src={cover} className="img-fluid" />
        </a>
      </Col>
      <Col className="articleDesc" lg={{ span: 14 }} xs={{ span: 24 }}>
        <div className="content">
          <div className="title">
            <a onClick={download}>
              {title}
              {titleExtra}
            </a>
          </div>
          <div className="description">{description}</div>
        </div>
        <div className="bottomSection">
          <div className="left">
            {/*<div className="author">作者：{author}</div>*/}
            {/*<div className="date">{date}</div>*/}
          </div>
          <div className="right">
            <a onClick={download}>点击下载</a>
            <a id={id} href={href} target="_blank" style={{ display: 'none' }} rel="noreferrer">
              {title}
            </a>
          </div>
        </div>
      </Col>
    </Row>
  );
}

// eslint-disable-next-line react/prop-types
const WhitePaper = ({ pageContext }) => {
  // eslint-disable-next-line react/prop-types
  const { dataList = [], total = 0, currentPage = 1, pageSize = 5 } = pageContext;

  useEffect(() => {
    hashSectionScrollIntoView();
  }, []);
  const handlePageChange = (page) => {
    window.location.href = page === 1 ? `/whitepaper/` : `/whitepaper/${page}/`;
  };
  return (
    <Layout current="sources" subCurrent="whitepaper">
      <SubscribeBanner
        title="白皮书下载"
        title2="数智赋能每一个岗位"
        backgroundImage="whitepaper-banner"
      />
      <section className="resources">
        <Container>
          {dataList.map((node) => (
            <Article key={node.id} {...node} />
          ))}
          <div className="pagiContainer">
            <Pagination
              total={total}
              pageSize={pageSize}
              hideOnSinglePage
              showSizeChanger={false}
              current={currentPage}
              responsive
              onChange={handlePageChange}
              itemRender={(page, type, originalElement) => {
                return (
                  <CustomPaginationItem
                    page={page}
                    type={type}
                    originalElement={originalElement}
                    hrefTag="whitepaper"
                  />
                );
              }}
            />
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default WhitePaper;
